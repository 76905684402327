import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image/compat"
// Hooks
import { useImageSrc } from "../hooks/useImageSrc"
// Components
import { Layout } from "../components/Layout"
// Constants
import { services} from "../constants/services"
import { SERVICES_ROUTE } from "../constants/routes"
// Styles
import "../styles/ServicesPage.sass"

export const ServicesPage = () => {
  const { src, srcSet } = useImageSrc()

  return (
    <StaticQuery
      query={graphql`
        query {
          footCare: file(
            relativePath: { eq: "assets/img/services/footcare.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          manicure: file(
            relativePath: { eq: "assets/img/services/manicure.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          tattoo: file(
            relativePath: { eq: "assets/img/services/tattoo2.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          microcurrentTherapy: file(
            relativePath: {
              eq: "assets/img/services/microcurrent-therapy.jpeg"
            }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          laserPigment: file(
            relativePath: { eq: "assets/img/services/laser-pigment.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          carbonPeeling: file(
            relativePath: { eq: "assets/img/services/carbon-peeling.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ultraphonophoresis: file(
            relativePath: { eq: "assets/img/services/ultraphonophoresis.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          cosmetology: file(
            relativePath: { eq: "assets/img/services/cosmetology.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          eyelashes: file(
            relativePath: { eq: "assets/img/services/decor.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          removing: file(
            relativePath: { eq: "assets/img/services/removing.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Layout
            title='Страница услуг | Студия красоты "Lab Beauty" Ирины Лучистой'
            description="Предоставляем услуги: перманентного макияжа (татуаж), маникюра, педикюра, ламинирование ресниц, моделирование ногтей гелем, удаление папиллом, удаление ремувером, лазерное удаление тату и татуажа, аппаратная косметология, осветление пигментных пятен"
            keywords="виды услуг салон красоты, услуги студии красоты, виды татуажа, виды маникюра, миды педикюра. ">
            <section className="services-page">
              <h1>Услуги</h1>
              {services.map((service, i) => {
                const fluid = data[service.name].childImageSharp.fluid

                return (
                  <Link to={`${SERVICES_ROUTE}/${service.route}`} key={i}>
                    <div
                      className="service"
                      data-sal={
                        i === 0 || i % 2 === 0 ? "slide-left" : "slide-right"
                      }
                      data-sal-duration="800"
                    >
                      {src && fluid && (
                        <GatsbyImage
                          placeholder={{ fallback: fluid.base64 }}
                          images={{
                            fallback: {
                              src: fluid.src,
                              srcSet: fluid.srcSet,
                            },
                            sources: [
                              {
                                src: fluid[src],
                                srcSet: fluid[srcSet],
                              },
                            ],
                          }}
                          alt={service.name}
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            left: 0,
                            right: 0,
                          }}
                        />
                      )}

                      <h2 className="title">{service.title}</h2>
                    </div>
                  </Link>
                )
              })}
            </section>
          </Layout>
        )
      }}
    />
  )
}

export default ServicesPage
